<!-- @format -->
<template>
    <div v-if="isLoaded && isVisible && breaking.length" class="na-breaking py-2">
        <breaking-list :breaking="breaking" @close="isVisible = false" />
    </div>
</template>

<script>
import BreakingList from './List.vue';
import { readPostsBreaking } from '@/services/post';

export default {
    components: {
        BreakingList
    },

    data() {
        return {
            isLoaded: false,
            isVisible: true,
            breaking: []
        };
    },

    async created() {
        await this.loadPostsBreaking();
        this.isLoaded = true;
    },

    methods: {
        async loadPostsBreaking() {
            this.breaking = [];
            const response = await readPostsBreaking();
            if (response.success) {
                this.breaking = response.posts;
            }
        }
    }
};
</script>
