<!-- @format -->
<template>
    <div v-if="items && items.length">
        <div class="columns is-mobile is-centered">
            <div class="column">
                <p class="has-text-centered" v-for="line in items[index].lines" :key="line.id">
                    <template v-if="line.link">
                        <template v-if="line.isExternalLink">
                            <a :href="line.link" rel="noopener noreferer" target="_blank">
                                <span class="has-text-white" :class="line.class">{{ text(line.text) }}</span>
                            </a>
                        </template>

                        <template v-else>
                            <span :class="line.class">{{ text(line.text) }}</span>
                            <span>&nbsp;</span>
                            <router-link class="has-text-white" :to="routeParams(item, line)">
                                <span class="icon is-small na-link">
                                    <fa-icon icon="arrow-circle-right" />
                                </span>
                            </router-link>
                        </template>
                    </template>

                    <template v-else>
                        <span class="has-text-white" :class="line.class">{{ text(line.text) }}</span>
                    </template>
                </p>

                <span
                    v-if="items[index].canClose"
                    class="icon is-medium na-stinger-close na-link"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Close"
                    @click="close"
                >
                    <fa-icon icon="times-circle" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { buildRouteParams } from '@/helpers/post';
import { miscMixin } from '@/mixins/MiscMixin.js';

export default {
    mixins: [miscMixin],

    props: {
        items: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },

    data() {
        return {
            index: 0
        };
    },

    methods: {
        /**
         * Previous index
         */
        prevIndex() {
            this.index--;
            if (!this.indexInBounds()) {
                this.index = this.items.length - 1;
            }
        },
        /**
         * Next index
         */
        nextIndex() {
            this.index++;
            if (!this.indexInBounds()) {
                this.index = 0;
            }
        },
        /**
         * User wants to close
         */
        close() {
            this.$emit('close');
        },
        /**
         * Check if index is in bounds
         *
         * @return {Boolean}
         */
        indexInBounds() {
            return this.index >= 0 && this.index < this.items.length;
        },
        routeParams(item, line) {
            if (!this.indexInBounds()) {
                return '/';
            }
            return line.isExternalLink
                ? '/'
                : buildRouteParams(item);
        },
        /**
         * Return text
         *
         * @param {string} text      Text
         * @param {mixed}  maxLength Max lenth of text, default is 50 characters - truncated after that
         */
        text(text, maxLength = 50) {
            return this.indexInBounds() ? this.dotString(text, maxLength) : null;
        }
    }
};
</script>
