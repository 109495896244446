<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column">
        <div v-if="!isLoaded && isBusy" class="columns mt-2">
            <div class="column has-text-centered">
                <busy-loader />
            </div>
        </div>

        <template v-if="isLoaded">
            <header v-if="!noHeader">
                <nav-bar />

                <content-menu-bar
                    v-if="
                        $route.meta.view === 'home' || $route.meta.view === 'post-viewer' || $route.name === 'landing'
                    "
                />
                <stinger-news />
            </header>

            <router-view
                v-if="($route.name === 'home' || $route.name === 'section' || $route.name === 'search') && $route.name !== 'hvnews'"
                :initial-message="flashedMessage"
            />

            <template v-else-if="routeIsPostViewer">
                <!-- Specific, single post -->
                <div v-if="!postNotFound" class="na-viewer-gradient section na-main-content-section mx-0">
                    <div class="container" :class="{ 'is-fluid': !clientIsMobile }">
                        <single-post :post="activePost" :post-count="1" :option-post-types="optionPostTypes"/>
                    </div>
                </div>
                <post-not-found v-else />
            </template>

            <page-not-found v-else-if="$route.name === 'not-found'" :path-name="$route.path" />

            <router-view v-else />

            <standard-modal
                v-if="$route.query.auth_view && !isSkipAuth"
                @close="$route.query.redirect ? $router.back() : $router.replace({ name: 'home' })"
            >
                <div class="box">
                    <auth
                        :initial-email="$route.query.init_email"
                        @close="$route.query.redirect ? $router.back() : $router.replace({ name: 'home' })"
                        @loggedIn="loggedIn"
                    />
                </div>
            </standard-modal>
        </template>
    </div>
</template>

<script>
import { loadOptionPostTypes } from '@/services/option';
import { mapActions } from 'vuex';
import { miscMixin } from '@/mixins/MiscMixin.js';
import { buildRouteParams } from '@/helpers/post.js';
import { readPostByIdentifier } from '@/services/post';
import ContentMenuBar from '@/components/Content/Nav/Index.vue';
import NavBar from '@/components/Common/Nav/Head/Index.vue';
import Auth from '@/components/User/Auth/Auth.vue';
import PageNotFound from './PageNotFound.vue';
import PostNotFound from './PostNotFound.vue';
import SinglePost from '@/components/Content/Viewer/Single.vue';
import StingerNews from '@/components/Content/Stinger/Index.vue';

export default {
    components: {
        ContentMenuBar,
        NavBar,
        Auth,
        PageNotFound,
        PostNotFound,
        SinglePost,
        StingerNews
    },

    mixins: [miscMixin],

    props: {
        flashedMessage: {
            type: String,
            default: ''
        },
        noHeader: {
            type: Boolean,
            default: false
        },
        noAuth: {  // Force no auth required?
            type: Boolean,
            default: false
        },
        forceIsPostViewer: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isLoaded: false,
            isBusy: true,
            activePost: null,
            postNotFound: false,
            unregisterRouteHook: null,
            optionPostTypes: []
        };
    },

    computed: {
        routeIsPostViewer() {
            return this.$route.meta.view === 'post-viewer';
        },

        isSkipAuth() {
            return this.noAuth || this.$route.meta.view === 'hvnews';
        },

        isForcePostViewer() {
            return this.forceIsPostViewer || this.$route.meta.view === 'hvnews';
        }
    },

    watch: {
        showWelcomeSection(val) {
            window.naShared.storage.setItem('showWelcomeSection', JSON.stringify(val));
        }
    },

    async created() {
        this.loadSections();
        const response = await loadOptionPostTypes();
        if (!response.success) {
            // no op - @todo report error?
        } else {
            this.optionPostTypes = response.postTypes;
        }
        this.unregisterRouteHook = this.$router.beforeEach(async (to, from, next) => {
            if (to.meta.view === 'post-viewer') {
                const urlParams = new URLSearchParams(to.query);
                urlParams.append('force_ignore_preferences', 1);
                next({
                    name: 'home',
                    query: {
                        ...from.query,
                        auth_view: 'login',
                        redirect: `${to.path}?${urlParams.toString()}`
                    }
                });
                const response = await readPostByIdentifier(to.params.identifier, true);
                if (response.success) {
                    this.$store.commit('user/setRecentPostId', response.post.id);
                    this.$store.commit('user/setRecentPost', response.post);
                }
            } else {
                next();
            }
        });
        this.init();
    },

    beforeDestroy() {
        if (this.unregisterRouteHook !== null) {
            this.unregisterRouteHook();
        }
    },

    methods: {
        ...mapActions({
            loadSections: 'user/loadSections'
        }),
        /**
         * Initialize
         */
        async init() {
            this.isBusy = true;
            await Promise.all([this.loadPost()]);
            this.isBusy = false;
            this.isLoaded = true;
        },

        async loadPost() {
            if (!this.$route.params.identifier) {
                return;
            }
            const response = await readPostByIdentifier(this.$route.params.identifier, true);
            if (!response.success) {
                this.postNotFound = true;
                this.isBusy = false;
                return;
            }
            this.activePost = response.post;
            if (!this.activePost) {
                this.postNotFound = true;
                return;
            }
            this.$store.commit('user/setRecentPostId', this.activePost.id);
            this.$store.commit('user/setRecentPost', this.activePost);
            // Short post link i.e. /post/link/:identifier route is temporary. After loading the post, redirect to post slug link
            if (this.$route.name === 'post-viewer-short') {
                this.$router.replace(buildRouteParams(this.activePost));
            }
        },

        loggedIn() {
            window.location.href = this.$route.query.redirect ?? '/';
        }
    }
};
</script>
