<!-- @format -->
<template>
    <div v-if="isLoaded && isVisible && items.length" class="na-stinger py-2">
        <stinger-list
            :items="items"
            @close="isVisible = false"
        />
    </div>
</template>

<script>
import StingerList from './List.vue';

export default {

    components: {
        StingerList
    },

    data() {
        return {
            isLoaded: false,
            isVisible: true,
            items: []
        };
    },

    computed: {
        mainPublicationSlug() {
            const mainPublication = this.$store.state.publication.mainPublication;
            //const mainPublication = { slug: 'kingston-wire'};
            return (!!mainPublication)
                ? mainPublication.slug
                : null;
        },
        shouldShow() {
            return this.mainPublicationSlug === 'kingston-wire' || this.mainPublicationSlug === 'shawangunk-journal';
            //return (!!(mainPublication && mainPublication.slug && mainPublication.slug === 'kingston-wire')) ||
            //    (!!(mainPublication && mainPublication.slug && mainPublication.slug === 'shawangunk-journal'));
        },
    },

    created() {
        switch (this.mainPublicationSlug) {
            case 'kingston-wire':
                this.items.push(
                {
                    id: 0,
                    canClose: false,
                    lines: [
                        {
                            text: 'Support Kingston Wire',
                            class: 'has-text-weight-bold is-size-6 is-size-7-mobile',
                            link: 'https://givebutter.com/EvnicK',
                            isExternalLink: true
                        },
                        {
                            text: 'Fundraiser at $1,206 towards $25,000 goal',
                            class: 'is-italic is-size-6 is-size-7-mobile',
                            link: 'https://givebutter.com/EvnicK',
                            isExternalLink: true
                        }
                    ]
                });
                this.isVisible = this.shouldShow;
                this.isLoaded = true;
                break;
            case 'shawangunk-journal':
                this.items.push(
                {
                    id: 0,
                    canClose: false,
                    lines: [
                        {
                            text: 'Support the Shawangunk Journal',
                            class: 'has-text-weight-bold is-size-6 is-size-7-mobile',
                            link: 'https://givebutter.com/tCfemE',
                            isExternalLink: true
                        },
                        {
                            text: 'Fundraiser at $4,521 towards $25,000 goal',
                            class: 'is-italic is-size-6 is-size-7-mobile',
                            link: 'https://givebutter.com/tCfemE',
                            isExternalLink: true
                        }
                    ]
                });
                this.isVisible = this.shouldShow;
                this.isLoaded = true;
                break;
        }
    },
};
</script>
