<!-- @format -->
<template>
    <div v-if="breaking && breaking.length">
        <div class="columns is-mobile is-centered">
            <div class="column">
                <p class="is-hidden-mobile has-text-centered">
                    <span class="icon is-small">
                        <fa-icon icon="bolt" />
                    </span>
                    <span class="na-breaking-h1">&nbsp;BREAKING NEWS...</span>
                    <span class="na-breaking-headline">{{ headline() }}</span>
                    <span>&nbsp;</span>
                    <router-link class="has-text-white" :to="routeParams">
                        <span class="icon is-small">
                            <fa-icon icon="arrow-circle-right" />
                        </span>
                    </router-link>
                </p>

                <p class="is-hidden-tablet has-text-centered">
                    <span class="icon is-small">
                        <fa-icon icon="bolt" />
                    </span>
                    <span class="na-breaking-h1">&nbsp;BREAKING NEWS...</span>
                </p>
                <p class="is-hidden-tablet has-text-centered">
                    <span class="na-breaking-headline">{{ headline() }}</span>
                    <span>&nbsp;</span>
                    <router-link class="has-text-white" :to="routeParams">
                        <span class="icon is-small na-link">
                            <fa-icon icon="arrow-circle-right" />
                        </span>
                    </router-link>
                </p>

                <span
                    class="icon is-medium na-breaking-close na-link"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Close breaking news"
                    @click="close"
                >
                    <fa-icon icon="times-circle" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { buildRouteParams } from '@/helpers/post';
import { miscMixin } from '@/mixins/MiscMixin.js';

export default {
    mixins: [miscMixin],

    props: {
        breaking: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },

    data() {
        return {
            index: 0
        };
    },

    computed: {
        routeParams() {
            if (!this.indexInBounds()) {
                return '/';
            }
            return buildRouteParams(this.breaking[this.index]);
        }
    },

    methods: {
        /**
         * Previous index
         */
        prevIndex() {
            this.index--;
            if (!this.indexInBounds()) {
                this.index = this.breaking.length - 1;
            }
        },
        /**
         * Next index
         */
        nextIndex() {
            this.index++;
            if (!this.indexInBounds()) {
                this.index = 0;
            }
        },
        /**
         * User wants to close
         */
        close() {
            this.$emit('close');
        },
        /**
         * Check if index is in bounds
         *
         * @return {Boolean}
         */
        indexInBounds() {
            return this.index >= 0 && this.index < this.breaking.length;
        },
        /**
         * Return headline
         *
         * @param {mixed} maxLength Max lenth of headline, default is 50 characters - truncated after that
         */
        headline(maxLength = 50) {
            return this.indexInBounds() ? this.dotString(this.breaking[this.index].title, maxLength) : null;
        }
    }
};
</script>
