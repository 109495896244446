<!-- @format -->
<template>
    <div class="na-quick-nav is-flex has-text-centered">
        <div class="is-inline-flex is-align-items-center my-1">
            <template
                v-if="
                    $route.name === 'home' ||
                    $route.name === 'section' ||
                    $route.name === 'search' ||
                    $route.name === 'landing'
                "
            >
                <span
                    v-if="isShowPrefNonPref"
                    class="na-quick-nav-timeframe"
                    data-toggle="tool-tip"
                    data-placement="bottom"
                    title="Ignored Preferences"
                >
                    <span
                        class="is-clickable px-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="ignorePreferencesTitle"
                        @click="togglePreferences"
                    >
                        <fa-icon :icon="['fas', ignorePreferences ? 'unlock-alt' : 'lock']" size="sm" />
                    </span>
                </span>

                <span v-if="isShowPrefNonPref" class="px-2">|</span>

                <span class="na-quick-nav-section na-quick-nav-timeframe">{{ homeName }}</span>
                <span class="px-2">|</span>
                <span class="na-quick-nav-date">{{ today }}</span>
            </template>

            <template v-if="view !== 'home'">
                <span
                    v-if="isShowPrefNonPref"
                    class="na-quick-nav-timeframe"
                    data-toggle="tool-tip"
                    data-placement="bottom"
                    title="Ignored Preferences"
                >
                    <span
                        class="is-clickable px-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="ignorePreferencesTitle"
                        @click="togglePreferences"
                    >
                        <fa-icon :icon="['fas', ignorePreferences ? 'unlock-alt' : 'lock']" size="sm" />
                    </span>
                </span>

                <span v-if="isShowPrefNonPref" class="px-2">|</span>

                <span v-if="isShowSection">
                    <span class="is-hidden-mobile na-quick-nav-section">
                        {{ dotString(activeSection.name, 20) }}
                    </span>
                    <span class="is-hidden-tablet na-quick-nav-section">
                        {{ dotString(activeSection.name, 12) }}
                    </span>
                </span>

                <router-link v-if="isShowReadMore" to="/">
                    <span class="na-quick-nav-section" data-toogle="tool-tip" data-placement="right" title="Read More">
                        Read More
                        <fa-icon icon="hand-point-right" />
                    </span>
                </router-link>

                <selector-event-date-type
                    v-if="isShowEventSelector"
                    :option-event-type="optionEventType"
                    :option-event-date="optionEventDate"
                    @update="eventDateTypeUpdated"
                />

                <span v-if="isShowSection || isShowEventSelector" class="px-2">|</span>

                <router-link v-if="isShowBackHome" class="na-quick-nav-return" :to="{ name: 'home' }"
                    ><fa-icon icon="arrow-circle-left" size="sm" /> <span>Home</span></router-link
                >

                <template v-if="isShowNavigationTools">
                    <span
                        v-if="isShowRefresh"
                        class="is-clickable px-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Refresh"
                        @click="refresh"
                    >
                        <fa-icon icon="redo" size="sm" />
                    </span>

                    <span
                        v-if="postCount !== null && postIndex !== null"
                        class="is-clickable px-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Previous Post"
                        @click="previousPost"
                    >
                        <fa-icon icon="step-backward" size="sm" />
                    </span>

                    <span v-if="postIndex === -1" class="px-1">1 / 1</span>
                    <span v-else-if="postCount !== null && postIndex !== null" class="px-1">
                        ({{ postIndex + 1 }} / {{ postCount }})
                    </span>

                    <span
                        v-if="postCount !== null && postIndex !== null"
                        class="is-clickable px-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Next Post"
                        @click="nextPost"
                    >
                        <fa-icon icon="step-forward" size="sm" />
                    </span>
                </template>
            </template>

            <template v-if="isShowBalance">
                <span v-if="view !== 'not-found'" class="px-2">|</span>
                <span class="na-quick-nav-balance" data-toggle="tooltip" data-placement="top" title="Your Balance">
                    <span class="is-hidden-mobile">Balance: </span>
                    {{ credits }}
                </span>
            </template>
        </div>
        <wm-status v-if="isShowWM" />
    </div>
</template>

<script>
import { getNow } from '@/helpers/date.js';
import { miscMixin } from '@/mixins/MiscMixin.js';
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { mapState } from 'vuex';
import { loadOptionPostTypeEventType, loadOptionPostTimeframeEventTypes } from '@/services/option.js';
import SelectorEventDateType from './Selectors/Event/EventDateType.vue';

export default {
    components: {
        SelectorEventDateType,
        
    },

    mixins: [miscMixin, moneyMixin],

    props: {
        postCount: {
            default: function () {
                return null;
            }
        },
        postIndex: {
            default: function () {
                return null;
            }
        }
    },

    data() {
        return {
            optionEventType: [],
            eventType: [],
            optionEventDate: [],
            isLoaded: false
        };
    },

    computed: {
        ...mapState({
            ignorePreferences: (state) => state.viewer.ignorePreferences,
            customSections: (state) => state.user.customSections.data,
            sectionsSpecial: (state) => state.user.sectionsSpecial.data,
            sections: (state) => state.user.sections.data
        }),

        view() {
            return this.$route.meta.view;
        },

        activeSection() {
            let sectionId = 0;
            if (this.$route.name === 'post-viewer-section') {
                sectionId = Number(this.$route.params.sectionId);
            } else if (this.$route.name === 'post-viewer') {
                sectionId = Number(this.$route.query.section_id);
            }
            let section = this.customSections.find((section) => section.id === sectionId);
            if (!section) {
                section = this.sectionsSpecial.find((section) => section.id === sectionId);
            }
            if (!section) {
                const sectionRoute = this.$route.query.section_route ?? this.$route.params.sectionRoute;
                section = this.sections.find((section) => section.route === sectionRoute);
            }
            return section;
        },

        homeName() {
            if (this.activeSection) {
                return this.activeSection.name;
            }
            if (this.$route.name === 'search') {
                return 'Search';
            }
            return 'Home';
        },

        isPostViewer() {
            return this.view === 'post-viewer';
        },
        /**
         * Return today's date
         *
         * @return {String}
         */
        today() {
            return getNow().format('MMMM D, YYYY');
        },
        /**
         * Return credits balance
         *
         * @return {String} Credits
         */
        credits() {
            return this.userGetBalance === null ? 'NONE' : this.formatMoney(this.userGetBalance);
        },
        /**
         * Return title tip for ignore preferences
         *
         * @return {String}
         */
        ignorePreferencesTitle() {
            return this.ignorePreferences ? 'Reader settings OFF' : 'Reader settings ON';
        },
        /**
         * Return section "post type"
         *
         * @return {mixed} Post type or null if none
         */
        sectionPostType() {
            return this.activeSection?.section_post_type?.key ?? null;
        },
        /**
         * Show balance?
         *
         * @return {Boolean}
         */
        isShowBalance() {
            return this.userIsLoggedIn;
        },
        /**
         * Show section name?
         *
         * @return {Boolean}
         */
        isShowSection() {
            return (
                this.userIsLoggedIn &&
                (this.isPostViewer || this.$route.name === 'section') &&
                this.activeSection &&
                !this.isAddingSubscription
            );
        },
        isEventSection() {
            return (
                this.sectionPostType === 'userSectionPostTypeEvents' ||
                this.$route.params.sectionRoute === 'calendar' ||
                this.$route.query.section_route === 'calendar'
            );
        },
        /**
         * Show special "Read More" link?  Only shown if user is not logged in
         *
         * @return {Boolean}
         */
        isShowReadMore() {
            return !this.userIsLoggedIn && this.$route.name !== 'landing';
        },
        /**
         * Show event selector?
         *
         * @return {Boolean}
         */
        isShowEventSelector() {
            return (
                this.userIsLoggedIn &&
                (this.isPostViewer || this.$route.name === 'post-viewer-section') &&
                this.isEventSection &&
                !this.isAddingSubscription
            );
        },
        /**
         * Show navigation tools?
         *
         * @return {Boolean}
         */
        isShowNavigationTools() {
            return this.userIsLoggedIn && this.isPostViewer && this.userIsLoggedIn && !this.isAddingSubscription;
        },
        /**
         * Show pref/non-pref tools?
         *
         * @return {Boolean}
         */
        isShowPrefNonPref() {
            return (
                (this.isPostViewer || this.view === 'home' || this.$route.name === 'section') &&
                this.userIsLoggedIn &&
                !this.isAddingSubscription
            );
        },
        /**
         * Show refresh?  Only applicable if already showing navigation tools - this is just a subset
         *
         * @return {Boolean}
         */
        isShowRefresh() {
            return this.userIsLoggedIn && !this.isAddingSubscription;
        },
        isAddingSubscription() {
            return Number(this.$route.query.subscribe) >= 1;
        },

        isSubscribePage() {
            return (
                this.$route.name === 'gift-subscription' ||
                this.$route.name === 'gift-subscription-start' ||
                this.$route.name === 'subscribe' ||
                this.$route.name === 'sjsubscribe' ||
                this.$route.name === 'kwsubscribe' ||
                this.$route.name === 'misubscribe' ||
                (this.userIsLoggedIn && this.$route.name === 'post-viewer' && this.isAddingSubscription)
            );
        },
        isShowBackHome() {
            return (
                this.isSubscribePage ||
                this.view === 'my-account' ||
                this.view === 'corporate' ||
                this.view === 'publication'
            );
        },
        isShowWM() {
            return this.isPostViewer && !!document.monetization;
        }
    },

    async created() {
        await this.load();
        this.isLoaded = true;
    },

    methods: {
        /**
         * Load data
         *
         * @param {Function} callback
         */
        async load() {
            //this.isBusy = true;
            this.optionEventType = [];
            this.optionEventDate = [];
            const [postTypeResponse, timeframeReponse] = await Promise.all([
                loadOptionPostTypeEventType(),
                loadOptionPostTimeframeEventTypes()
            ]);
            if (!postTypeResponse.success) {
                this.errors = postTypeResponse.errors;
            } else {
                this.optionEventType = postTypeResponse.postTypeEventType;
                this.optionEventType.unshift({
                    id: null,
                    value: null,
                    text: 'Any'
                });
            }
            if (!timeframeReponse.success) {
                this.errors = timeframeReponse.errors;
            } else {
                timeframeReponse.timeframeTypes.forEach((item) => {
                    if (item.value === 'postTimeframeEventTypeDates') {
                        // Only want to a allow a single date for this type, so just change the name
                        item.text = 'Specific Date';
                    }
                    this.optionEventDate.push(item);
                });
                this.optionEventDate.unshift({
                    id: null,
                    value: null,
                    text: 'Any'
                });
            }
        },
        /**
         * Go to the previous post
         */
        previousPost() {
            this.$root.$emit('prev-post');
        },
        /**
         * Go to the next post
         */
        nextPost() {
            this.$root.$emit('next-post');
        },
        /**
         * Refresh the feed
         */
        refresh() {
            this.$root.$emit('refresh-post');
        },
        /**
         * User wants to toggle preferences
         */
        togglePreferences() {
            this.$store.dispatch('viewer/setIgnorePreferences', !this.ignorePreferences);
        },
        /**
         * Event date/type selector updated
         *
         * @param {Object} event Event object - may be null if none
         */
        eventDateTypeUpdated(event) {
            this.$emit('updated-event-date-type', event);
        }
    }
};
</script>
