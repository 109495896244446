<!-- @format -->
<template>
    <div v-if="isLoaded" class="is-flex is-flex-direction-column">
        <header v-if="!noHeader">
            <content-nav-bar />

            <content-menu-bar
                :post-count="postCount"
                :post-index="postIndex"
                @updated-event-date-type="eventDateTypeUpdated"
            />

            <breaking-news />
            <stinger-news />
        </header>

        <!-- @todo temporary disable keep-alive so that data loading is vastly simplified. Having problem with navigation between sections and home. -->
        <!-- Trade-off is that scroll-position is not preserved. -->
        <router-view
            v-if="
                $route.name === 'post-viewer' ||
                $route.name === 'post-viewer-short' ||
                $route.name === 'post-viewer-section' ||
                $route.name === 'post-viewer-auto-purchase' ||
                $route.name === 'post-viewer-short-auto-purchase'
            "
            :option-post-types="optionPostTypes"
            :req-event-type="reqEventType"
            :req-event-date="reqEventDate"
            :initial-message="flashedMessage"
            @article-purchased="articlePurchased"
            @post-index-updated="postIndexUpdated"
            @post-count-updated="postCountUpdated"
        />

        <keep-alive v-else-if="$route.name === 'home' || $route.name === 'section' || $route.name === 'search'">
            <router-view :initial-message="flashedMessage" />
        </keep-alive>

        <router-view v-else />
        <now-playing
            v-if="nowPlaying.isActive"
            :url="nowPlaying.url"
            :title="nowPlaying.title"
            play-immediately
            @close="stopNowPlaying"
        />
    </div>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';
import { loadOptionPostTypes } from '@/services/option';
import BreakingNews from '@/components/Content/Breaking/Index.vue';
import ContentMenuBar from '@/components/Content/Nav/Index.vue';
import ContentNavBar from '@/components/Common/Nav/Head/Index.vue';
import NowPlaying from '@/components/Content/NowPlaying/Index.vue';
import StingerNews from '@/components/Content/Stinger/Index.vue';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    components: {
        BreakingNews,
        ContentMenuBar,
        ContentNavBar,
        NowPlaying,
        StingerNews
    },

    mixins: [postMixin],

    props: {
        flashedMessage: {
            type: String,
            default: ''
        },
        noHeader: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            optionPostTypes: [],
            postCount: null,
            postIndex: null,
            isLoaded: false,
            reqEventType: null,
            reqEventDate: null
        };
    },

    computed: {
        ...mapState({
            nowPlaying: (state) => state.viewer.nowPlaying
        })
    },

    async created() {
        if (this.$route.query.auth_view) {
            this.$router.replace({ name: 'home' });
        }

        if (this.$route.query.transfer_ignorePreferences) {
            this.$store.dispatch('viewer/setIgnorePreferences', this.$route.query.transfer_ignorePreferences === '1');
            this.$router.replace({
                name: this.$route.name,
                params: this.$route.params,
                query: {
                    ...this.$route.query,
                    transfer_ignorePreferences: undefined
                }
            });
        }

        this.loadUserInfo(); // Reload user info to refresh balance
        this.loadSections();

        await this.load();
        this.isLoaded = true;
    },

    methods: {
        ...mapMutations({
            stopNowPlaying: 'viewer/stopPodcast'
        }),
        ...mapActions({
            loadSections: 'user/loadSections'
        }),
        /**
         * Load any initial data
         *
         * @param {Function} callback Function to call when load is complete
         */
        async load() {
            const response = await loadOptionPostTypes();
            if (!response.success) {
                if (response.errors) {
                    this.errors = response.errors;
                }
            } else {
                this.optionPostTypes = response.postTypes;
            }
        },
        /**
         * An article was purchased, refresh user's remaining credits
         */
        articlePurchased() {
            this.loadUserInfo(); // Reload user info to refresh balance
        },
        /**
         * Post count was updated
         *
         * @param {Integer} postCount Count of posts
         */
        postCountUpdated(postCount) {
            this.postCount = postCount;
        },
        /**
         * Post index was updated
         *
         * @param {Integer} postIndex Post index
         */
        postIndexUpdated(postIndex) {
            this.postIndex = postIndex;
        },
        /**
         * Event date/type(s) updated
         *
         * @param {Object} event Event object, null if none
         */
        eventDateTypeUpdated(event) {
            this.reqEventDate = event && event.eventDate ? event.eventDate : null;
            this.reqEventType = event && event.eventType ? event.eventType : null;
        }
    }
};
</script>
