<!-- @format -->
<template>
    <div class="now-playing has-background-primary pt-3 pb-1">
        <div class="container">
            <audio-player :url="url" :title="title" :play-immediately="playImmediately" @close="$emit('close')" />
        </div>
    </div>
</template>

<script>
import AudioPlayer from '@/components/Common/AudioPlayer.vue';
export default {
    components: {
        AudioPlayer
    },

    props: {
        url: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        playImmediately: {
            type: Boolean,
            default: false
        }
    }
};
</script>
