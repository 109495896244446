<!-- @format -->
<template>
    <div>
        <div class="columns">
            <div class="column is-offset-3-tablet is-6-tablet is-offset-1-mobile is-10-mobile na-post-not-found">
                <div class="columns">
                    <div class="column">
                        <p class="has-text-centered na-exclamation">!</p>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <p class="has-text-centered is-size-3">Post not available.</p>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <p class="has-text-centered is-size-4">
                            <router-link class="na-link" :to="{ name: 'home', query: { auth_view: 'login' } }"
                                >Login or create an account</router-link
                            >
                            <br />
                            to see what NewsAtomic has to offer.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        reason: {
            type: String,
            default: 'general'
        }
    }
};
</script>
