<!-- @format -->
<template>
    <div class="na-publication-wrapper" :class="mainPublicationClass">
        <template v-if="isAuth">
            <div v-if="userIsImpersonating" class="container na-debug-environment">
                <na-environment :add-container-wrapper="false" />

                <div class="columns">
                    <div class="column">
                        <p class="is-size-6 na-user-impersonation has-text-centered">
                            NOTE: You are currently impersonating user id {{ userCurrent.id }}, {{ userCurrent.email }}
                            <span
                                >&nbsp;&nbsp;<button class="button is-text" @click="userImpersonateOff()">
                                    (click to disable)
                                </button></span
                            >
                        </p>
                    </div>
                </div>
            </div>
            <na-environment v-else />
            <user-home-authenticated
                :flashed-message="flashedMessage"
                :no-header="noHeader"
            />
        </template>

        <template v-else>
            <na-environment />
            <user-home-unauthenticated
                :flashed-message="flashedMessage"
                :force-is-post-viewer="forceUnauthPostViewer"
                :no-auth="noAuth"
                :no-header="noHeader"
            />
        </template>
    </div>
</template>

<script>
import { setOptions } from 'vue-gtag';
import { mapState, mapMutations, mapActions } from 'vuex';
import { userLogin } from '@/services/user';
import NaEnvironment from '@/components/Common/Environment.vue';
import UserHomeUnauthenticated from '@/components/User/Intro/Unauthenticated/Index.vue';
import UserHomeAuthenticated from '@/components/Content/Index.vue';

export default {
    components: {
        NaEnvironment,
        UserHomeAuthenticated,
        UserHomeUnauthenticated
    },

    props: {
        userData: {
            type: Object,
            required: true
        },
        flashedMessage: {
            type: String,
            default: ''
        },
        mainPublication: {
            type: Object,
            default: null
        },
        instantiator: {
            type: String,
            default: 'base'
        },
        noAuth: {  // Force no auth needed
            type: Boolean,
            default: false
        },
        forceUnauthPostViewer: {
            type: Boolean,
            default: false
        },
        forceHvnewsLogin: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            noHeader: Number(new URLSearchParams(window.location.search).get('no-header') || 0) === 1,
            isAuth: this.userData.isLoggedIn
        };
    },

    computed: {
        ...mapState({
            ignorePreferences: (state) => state.viewer.ignorePreferences
        }),

        mainPublicationClass() {
            switch (this.mainPublication?.slug) {
                case 'kingston-wire':
                    return 'na-publication-slug-kingston-wire';
                case 'shawangunk-journal':
                    return 'na-publication-slug-shawangunk-journal';
                case 'dundalk-herald':
                    return 'na-publication-slug-dundalk-herald';
                default:
                    return 'na-publication-generic';
            }
        }
    },

    watch: {
        ignorePreferences() {
            if (this.init) {
                // Native ads depend on the preferences, so we need to reload native ads if the preferences flag changes
                this.loadNativeAds();
            }
        },
        '$route.query.q'(val) {
            if (val) {
                window.sessionStorage.setItem('search_query', val);
            }
        }
    },

    async created() {
        //console.log('DEBUG: In Root/App.vue');
        //console.log('DEBUG: import.meta=', import.meta);
        //console.log(import.meta.env.VITE_REHOME_MEDIA_URL); // https://media.example.com
        if (this.forceHvnewsLogin) {
            await this.doHvnewsLogin();
        }
        this.loadNativeAds();

        if (this.mainPublication) {
            this.setMainPublication(this.mainPublication);
        }

        this.setInstantiator(this.instantiator);

        if (this.isAuth) {
            this.loadUserData(this.userData);
        }

        // Track additional property?  This is when the site is accessed via a "main" publication (e.g. through a subdomain like kingstonwire.newsatomic.com)
        // Note that we have to use the id, not the slug because the slug probably wouldn't be set yet above from the loadMainPublication call - I suppose we could pass the slug to the component

        // Kingston Wire
        if (this.mainPublication?.id === 27) {
            setOptions({
                includes: [{ id: 'G-FBZJGP2WHD' }] // UA-104540283-2
            });
        }

        // Shawangunk Journal
        if (this.mainPublication?.id === 1) {
            setOptions({
                includes: [{ id: 'G-86L2MMXE36' }] // UA-104540283-3
            });
        }
    },

    methods: {
        ...mapActions({
            vuexloadNativeAds: 'viewer/loadNativeAds',
            loadUserData: 'user/loadFromObject'
        }),
        ...mapMutations({
            setMainPublication: 'publication/setMainPublication',
            setInstantiator: 'viewer/setInstantiator'
        }),
        loadNativeAds() {
            this.vuexloadNativeAds({
                request: {
                    options: {
                        ignorePreferences: this.ignorePreferences
                    }
                }
            });
        },

        async doHvnewsLogin() {
            // If not logged in, fake it:
            if (!this.userIsLoggedIn) {
                // Fake it
                const response = await userLogin('hvnews-login@zelacom.com', 'dfhdgfhjhr48#HUHFJHFh4jr4hj4h', false);
                if (response.success) {
                    await this.loadUserInfo();
                    this.isAuth = true;
                }
                //this.$emit('loggedIn');
            }
        }
    }
};
</script>
