/** @format */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap.js';
//import '../css/app.css';
import './version.js';

import Vue from 'vue';
//import Vue from 'vue/dist/vue.esm.js';
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText
} from './font-awesome.js';

import { clientMixin } from './mixins/ClientMixin.js';
import { errorMixin } from './mixins/ErrorMixin.js';
import { publicationMixin } from './mixins/PublicationMixin.js';
import { userMixin } from './mixins/UserMixin.js';
import App from './components/Root/App.vue';
import ShowError from './components/Common/Error.vue';
import ShowSuccess from './components/Common/Success.vue';
import CardModal from './components/Common/Modal/Card.vue';
import StandardModal from './components/Common/Modal/Standard.vue';
import Field from './components/Common/BasicField.vue';
import HorizontalField from './components/Common/HorizontalField.vue';
import BusyLoader from './components/Common/BusyLoader.vue';
import vClickOutside from 'v-click-outside';
import VueRouter from 'vue-router';
import VueSweetAlert from 'vue-sweetalert';
import VueClipboard from 'vue-clipboard2';
import Vuex from 'vuex';
import store from './store/index.js';
import router from './router/index.js';
import VueMeta from 'vue-meta';
import VueGtag from 'vue-gtag';

/*
if (process.env.NODE_ENV === 'production') {
    // Disable vue devtools, debug in production mode
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
}
*/

Vue.use(Vuex);
Vue.use(VueMeta);
Vue.use(VueRouter);
//if (process.env.NODE_ENV === 'production') {
    Vue.use(
        VueGtag,
        {
            config: {
                id: 'G-CEHRJ2XKZH' // UA-104540283-1
            },
            bootstrap: true
        },
        router
    );
//}
Vue.use(VueSweetAlert);
Vue.use(VueClipboard);
Vue.use(vClickOutside);
Vue.component('BusyLoader', BusyLoader); // Register BusyLoader as a global component
Vue.component('CardModal', CardModal);
Vue.component('StandardModal', StandardModal);
Vue.component('Field', Field);
Vue.component('HorizontalField', HorizontalField);
Vue.component('FaIcon', FontAwesomeIcon);
Vue.component('FaLayers', FontAwesomeLayers);
Vue.component('FaLayersText', FontAwesomeLayersText);

Vue.mixin(clientMixin);
Vue.mixin(errorMixin);
Vue.mixin(publicationMixin);
Vue.mixin(userMixin);

store.commit(
    'viewer/setPreferences',
    JSON.parse(window.naShared.storage.getItem('ignorePreferences')) ?? true
);

window.loadRecaptchaApi = function () {
    store.commit('app/setRecaptchaLoaded', true);
};

window.loadGoogleApi = function loadGoogleApi() {
    window.gapi.load('auth2', function () {
        window.googleAuthClient = window.gapi.auth2.init({
            client_id: document.head.children['google-signin-client_id'].content
        });
        store.commit('app/setGoogleAuthClientLoaded', true);
    });
};

const app = new Vue({
    el: '#app',
    store,
    router,
    components: {
        App,
        ShowError,
        ShowSuccess
    },
    created() {
        store.dispatch('tracking/initParametersFromUrl');
    }
});

if (import.meta.env.MODE !== 'production') {
    window.app = app;
}
