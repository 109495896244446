<!-- @format -->
<template>
    <div class="columns is-centered">
        <div class="column is-6-tablet is-10-mobile na-post-not-found">
            <p class="has-text-centered na-exclamation my-2">!</p>

            <p class="has-text-centered is-size-3" my-2>That page is not available.</p>

            <p class="has-text-centered is-size-4 my-2">
                <router-link class="na-link" :to="{ name: 'home', query: { auth_view: 'login' } }"
                    >Login or create an account</router-link
                >
                <br />
                to see what NewsAtomic has to offer.
            </p>
        </div>
    </div>
</template>

<script>

export default {
    props: {
            pathName: {
                type: String,
                default: ''
            },
        },
    async created() {
        
        if(this.pathName == '/delete-account'){ 
            this.$router.push({ name: 'delete-account', query: { auth_view: 'login' , redirect : 'delete-account'  } });
        }
    }
};

</script>
