<!-- @format -->
<template>
    <div
        v-if="userIsLoggedIn && optionEventDate && optionEventDate.length && optionEventType && optionEventType.length"
        v-click-outside="clickedOutside"
        class="dropdown na-quick-nav-dropdown"
        :class="{ 'is-active': showDropdown }"
    >
        <div class="dropdown-trigger na-link" @click="showDropdown = !showDropdown">
            <button type="button" class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                <span
                    class="icon is-medium na-quick-nav-dropdown-selector"
                    data-toggle="tooltip"
                    title="Event Date and Type"
                >
                    <fa-icon icon="calendar" />
                </span>
            </button>
        </div>

        <div class="dropdown-menu na-dropdown-left-adjust">
            <div class="dropdown-content">
                <div class="dropdown-item">
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label has-text-left">Type of Event</label>
                                <div class="control">
                                    <div class="select">
                                        <select v-model="eventType">
                                            <option
                                                v-for="option in optionEventType"
                                                :key="option.id"
                                                :value="option.id"
                                            >
                                                {{ option.text }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <div class="control">
                                    <label class="label has-text-left">Date of Event</label>
                                    <div class="select">
                                        <select v-model="eventDate">
                                            <option
                                                v-for="option in optionEventDate"
                                                :key="option.id"
                                                :value="option.id"
                                            >
                                                {{ option.text }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="getOptionValue(optionEventDate, eventDate) === 'postTimeframeEventTypeDates'"
                        class="columns"
                    >
                        <div class="column">
                            <flatpicker-date-time-picker
                                :is-horizontal="false"
                                input-class="input na-datepicker-input"
                                only-date
                                placeholder="Select specific date"
                                :show-icon="false"
                                :value.sync="eventDateSpecific"
                            />
                        </div>
                    </div>

                    <div class="columns has-text-left">
                        <div class="column">
                            <a class="button is-success" role="button" @click="okay">
                                <span class="icon is-small">
                                    <fa-icon icon="check" />
                                </span>
                                <span> OK </span>
                            </a>

                            &nbsp;

                            <a class="button is-danger" role="button" @click="cancel">
                                <span class="icon is-small">
                                    <fa-icon icon="times" />
                                </span>
                                <span> Cancel </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';
import FlatpickerDateTimePicker from '@/components/Common/FlatpickerDateTimePicker.vue';

export default {
    components: {
        FlatpickerDateTimePicker
    },

    mixins: [postMixin],

    props: {
        optionEventDate: {
            type: Array,
            default: function () {
                return [];
            }
        },
        optionEventType: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },

    data() {
        return {
            showDropdown: false,
            eventType: null,
            eventDate: null,
            eventDateSpecific: null
        };
    },

    methods: {
        /**
         * User clicked okay
         */
        okay() {
            this.$emit('update', {
                eventType: this.eventType,
                eventDate: {
                    eventDate: this.eventDate,
                    eventDateSpecific: this.eventDateSpecific
                }
            });
            this.showDropdown = false;
        },
        /**
         * User clicked cancel
         */
        cancel() {
            this.$emit('cancel');
            this.showDropdown = false;
        },
        /**
         * User clicked outside of content area
         */
        clickedOutside() {
            if (this.showDropdown) {
                this.$emit('cancel');
                this.showDropdown = false;
            }
        }
    }
};
</script>
